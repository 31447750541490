<template>
  <div class="l-select" :value="$attrs.value" @click.capture="visible = true" :class="{ active: visible }">
    <!-- select tag -->
    <div class="tag" v-show="tagName">{{ tagName }}</div>
    <!-- select main -->
    <el-form-item :label="label" :prop="name">
      <el-select
        v-on="listeners"
        v-bind="$attrs"
        class="showPlaceholder"
        :class="{ selected: listeners, creditCard: name === 'creditCard', ccReset: reset }"
        :disabled="disabled || reset"
        :data-testid="testId || name"
        placeholder=""
        popper-class="withdraw-select"
        :value="testId"
      >
        <el-option v-for="item in withdrawalTypeList" :key="item.id" :value="item.id">
          <div class="select-options-container">
            <div class="select-text">{{ $t(item.nameKey) }}</div>
            <img class="select_img type-Img" :src="withdrawalIMG(item.id)" alt />
          </div>
        </el-option>

        <template v-if="listeners" #prefix>
          <div v-if="chooseWithdrawalType">
            <img class="selected-img type-Img" :src="withdrawalIcon(chooseWithdrawalType.id)" alt />
            <div class="select-text">
              <p>{{ $t(chooseWithdrawalType.nameKey) }}</p>
            </div>
            <div class="amount" :class="{ mr_amount: ['zh_CN', 'ja', 'ko', 'vi'].includes(lang) }">
              {{ '$' + amount }}
            </div>
          </div>
          <div v-else-if="showCreditCard">
            <img class="type-Img selected-img" src="@/assets/images/withdraw/master_card_icon.png" alt />
            <div class="select-text">
              {{ $t('common.withdrawChannel.creditcard') }}
              <i class="el-icon-info" :title="$t('withdraw.default.remainingWithdraw')"></i>
            </div>
            <div class="amount" :class="{ mr_amount: ['zh_CN', 'ja', 'ko', 'vi'].includes(lang) }">
              {{ '$' + amount }}
            </div>
          </div>
          <div v-else class="select-text">
            {{ selectText }}
          </div>
        </template>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
// import all withdraw types
import CreditCardWithdraw from './types/CreditCardWithdraw';
import BankTransfer from './types/BankTransfer';
import NetellerWithdraw from './types/NetellerWithdraw';
import FasapayWithdraw from './types/FasapayWithdraw';
import SkrillWithdraw from './types/SkrillWithdraw';
import ThaMalVieWithdraw from './types/ThaMalVieWithdraw';
import UnionpayWithdrawCps from './types/UnionpayWithdrawCps';
import BitcoinWithdraw from './types/BitcoinWithdraw';
import CryptoCpsWithdraw from './types/CryptoCpsWithdraw';
import UsdtWithdraw from './types/UsdtWithdraw';
import KoreaWithdraw from './types/KoreaWithdraw';
import BitwalletWithdraw from './types/BitwalletWithdraw';
import SticpayWithdraw from './types/SticpayWithdraw';
import IndonesianWithdraw from './types/IndonesianWithdraw';
import PhpAndSAWithdraw from './types/PhpAndSAWithdraw';
import AstropayWithdraw from './types/AstropayWithdraw';
import NigeriaWithdraw from './types/NigeriaWithdraw';
import IndiaWithdraw from './types/IndiaWithdraw';
import AfricaWithdraw from './types/AfricaWithdraw';
import PerfectMoneyWithdraw from './types/PerfectMoneyWithdraw';
import CanadaWithdraw from './types/CanadaWithdraw';
import LocalDepositorWithdraw from './types/LocalDepositorWithdraw';

export default {
  name: 'SelectWithdraw',
  props: {
    name: String,
    label: String,
    testId: String,
    availableTypes: Array,
    amount: Number,
    disabled: Boolean,
    reset: Boolean,
    showCreditCard: {
      type: Boolean,
      default: false
    },
    selectText: String,
    tagName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      withdrawalType: null,
      withdrawalTypeList: [],
      withdrawalTypesMap: {
        1: {
          component: CreditCardWithdraw,
          img: require('@/assets/images/withdraw/master_card_select.png'),
          icon: require('@/assets/images/withdraw/master_card_icon.png')
        },
        2: {
          component: BankTransfer,
          img: require('@/assets/images/withdraw/swift_select.png'),
          icon: require('@/assets/images/withdraw/swift_icon.png')
        },
        4: {
          component: UnionpayWithdrawCps,
          img: require('@/assets/images/withdraw/union_pay_select.png'),
          icon: require('@/assets/images/withdraw/union_pay_icon.png')
        },
        5: {
          component: ThaMalVieWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        6: {
          component: ThaMalVieWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        7: {
          component: FasapayWithdraw,
          img: require('@/assets/images/withdraw/fasapay_select.png'),
          icon: require('@/assets/images/withdraw/fasapay_icon.png')
        },
        8: {
          component: ThaMalVieWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        9: {
          component: NigeriaWithdraw,
          img: require('@/assets/images/withdraw/nigeria_select.png'),
          icon: require('@/assets/images/withdraw/nigeria_icon.png')
        },
        24: {
          component: IndiaWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        31: {
          component: SkrillWithdraw,
          img: require('@/assets/images/withdraw/skrill_select.png'),
          icon: require('@/assets/images/withdraw/skrill_icon.png')
        },
        32: {
          component: NetellerWithdraw,
          img: require('@/assets/images/withdraw/neteller_select.png'),
          icon: require('@/assets/images/withdraw/neteller_icon.png')
        },
        34: {
          component: BitcoinWithdraw,
          img: require('@/assets/images/withdraw/bitcoin_select.png'),
          icon: require('@/assets/images/withdraw/bitcoin_icon.png')
        },
        35: {
          component: KoreaWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        36: {
          component: UsdtWithdraw,
          img: require('@/assets/images/withdraw/usdt_select.png'),
          icon: require('@/assets/images/withdraw/usdt_icon.png')
        },
        37: {
          component: BitwalletWithdraw,
          img: require('@/assets/images/withdraw/bitcoin_select.png'),
          icon: require('@/assets/images/withdraw/bitcoin_icon.png')
        },
        38: {
          component: SticpayWithdraw,
          img: require('@/assets/images/withdraw/sticpay_select.png'),
          icon: require('@/assets/images/withdraw/sticpay_icon.png')
        },
        39: {
          component: IndonesianWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        40: {
          component: PhpAndSAWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        // 41: {
        //   component: AstropayWithdraw,
        //   img: require('@/assets/images/withdraw/astropay_select.png'),
        //   icon: require('@/assets/images/withdraw/astropay_icon.png')
        // },
        42: {
          component: PhpAndSAWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        50: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        51: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        52: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        53: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        54: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        55: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        56: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        57: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        58: {
          component: AfricaWithdraw,
          img: require('@/assets/images/withdraw/bank_transfer_select.png'),
          icon: require('@/assets/images/withdraw/bank_transfer_icon.png')
        },
        60: {
          component: CanadaWithdraw,
          img: require('@/assets/images/withdraw/interac.png'),
          icon: require('@/assets/images/withdraw/interac_icon.png')
        },
        61: {
          component: PerfectMoneyWithdraw,
          img: require('@/assets/images/withdraw/perfect_money_select.png'),
          icon: require('@/assets/images/withdraw/perfect_money_icon.png')
        },
        65: {
          component: LocalDepositorWithdraw,
          img: require('@/assets/images/withdraw/vietna_select.png'),
          icon: require('@/assets/images/withdraw/vietna_icon.png')
        },
        80: {
          component: CryptoCpsWithdraw,
          img: require('@/assets/images/withdraw/eth_select.png'),
          icon: require('@/assets/images/withdraw/eth_icon.png')
        },
        81: {
          component: CryptoCpsWithdraw,
          img: require('@/assets/images/withdraw/usdc_select.png'),
          icon: require('@/assets/images/withdraw/usdc_icon.png')
        }
      },
      chooseWithdrawalType: null,
      currentComponent: null,
      ngaCountryCode: 5796
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          this.currentComponent = this.withdrawalTypesMap[event].component;
          this.chooseWithdrawalType = this.withdrawalTypeList.find(f => f.id == event);
          this.withdrawalType = event;
          this.emitWithdraw();
        }
      });
    },
    lang() {
      return this.$store.state.common.lang;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    regulator() {
      return this.$store.state.common.regulator;
    }
  },
  watch: {
    reset(val) {
      if (!val) {
        this.emitWithdraw();
      }
    },
    availableTypes(val) {
      let tmpList = [];
      Object.keys(this.withdrawalTypesMap).map(function(key) {
        tmpList.push(...val.filter(el => el.id == key));
      });
      this.withdrawalTypeList = tmpList;
    },
    withdrawalTypeList(val) {
      if (!val) return;
      if (!val.find(f => f.id == this.withdrawalType)) {
        this.currentComponent = null;
        this.chooseWithdrawalType = null;
        this.withdrawalType = null;
        this.emitWithdraw();
      }
    },
    disabled(val) {
      if (val) {
        this.currentComponent = null;
        this.chooseWithdrawalType = null;
        this.withdrawalType = null;
        this.emitWithdraw();
      }
    }
  },
  methods: {
    emitWithdraw() {
      this.$emit('setWithdraw', {
        currentComponent: this.currentComponent,
        chooseWithdrawalType: this.chooseWithdrawalType,
        withdrawalType: this.withdrawalType
      });
    },
    withdrawalIMG(id) {
      //change nigeria bank transfer img
      if (id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode)
        return this.withdrawalTypesMap[9].img;
      return this.withdrawalTypesMap[id].img;
    },
    withdrawalIcon(id) {
      //change nigeria bank transfer img
      if (id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode)
        return this.withdrawalTypesMap[9].icon;
      return this.withdrawalTypesMap[id].icon;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/selectWithdraw.scss';
</style>
