<template>
  <PageWrapper>
    <IncompleteRemind></IncompleteRemind>
    <PagePanel v-if="showWithdraw">
      <TopLeft ref="topLeft" @setForm="getForm" @setTopForm="getTopForm" @reset="reset"></TopLeft>
      <div v-if="showRight">
        <hr class="vl" />
      </div>
      <TopRight
        :withdrawForm="withdrawForm"
        :topForm="topForm"
        :reset.sync="resetForm"
        :showRight="showRight"
        :disabled="disableOtherMeThod"
        @setWithdraw="getWithdraw"
      ></TopRight>
      <div v-if="!resetForm">
        <CcMethod
          ref="ccMethod"
          v-if="topForm.showCC"
          @disableOtherMethod="onDisableOtherMethod"
          :withdrawForm="withdrawForm"
          :topForm="topForm"
          :isShowOtherPayment="topForm.showOtherPayment"
        ></CcMethod>
        <OtherMethod
          ref="otherMethod"
          v-if="(topForm.showOtherPayment && chooseWithdraw.chooseWithdrawalType) || disableOtherMeThod"
          :topForm="topForm"
          :withdrawForm="withdrawForm"
          :bankList="bankList"
          :disabled="disableOtherMeThod"
          :chooseWithdraw="chooseWithdraw"
        />
      </div>

      <!-- submit area -->
      <div class="box" v-if="showSubmit">
        <div class="box-card-verification">
          <VerificationCode ref="verificationCode" namespace="CP_WITHDRAW"></VerificationCode>
        </div>
        <div class="box-card-submit">
          <el-checkbox v-model="checkStatus" class="policy-checkbox" data-testid="checkbox">
            <i18n path="withdraw.default.agreePolicy">
              <template v-slot:link>
                <a @click.prevent="disclaimerVisible = true" data-testid="withdrawPolicy">
                  {{ $t('withdraw.default.withdrawPolicy') }}</a
                >
              </template>
            </i18n>
          </el-checkbox>
          <div>
            <el-button class="btn-blue submit-btn" @click="submitForm()" :disabled="!checkStatus" data-testid="submit">
              {{ $t('withdraw.default.submitYourWithdrawal') }}
            </el-button>
          </div>
        </div>
      </div>
    </PagePanel>
    <!-- withdraw process -->
    <div class="content-box" v-show="formFlag">
      <!-- main content -->
      <div v-if="showWithdraw" class="inner">
        <div class="withDraw-form">
          <div class="box box-card-top">
            <TopLeft ref="topLeft" @setForm="getForm" @setTopForm="getTopForm" @reset="reset"></TopLeft>
            <div v-if="showRight">
              <hr class="vl" />
            </div>
            <TopRight
              :withdrawForm="withdrawForm"
              :topForm="topForm"
              :reset.sync="resetForm"
              :showRight="showRight"
              :disabled="disableOtherMeThod"
              @setWithdraw="getWithdraw"
            ></TopRight>
          </div>
          <div v-if="!resetForm">
            <CcMethod
              ref="ccMethod"
              v-if="topForm.showCC"
              @disableOtherMethod="onDisableOtherMethod"
              :withdrawForm="withdrawForm"
              :topForm="topForm"
              :isShowOtherPayment="topForm.showOtherPayment"
            ></CcMethod>
            <OtherMethod
              ref="otherMethod"
              v-if="(topForm.showOtherPayment && chooseWithdraw.chooseWithdrawalType) || disableOtherMeThod"
              :topForm="topForm"
              :withdrawForm="withdrawForm"
              :bankList="bankList"
              :disabled="disableOtherMeThod"
              :chooseWithdraw="chooseWithdraw"
            />
          </div>

          <!-- submit area -->
          <div class="box" v-if="showSubmit">
            <div class="box-card-verification">
              <VerificationCode ref="verificationCode" namespace="CP_WITHDRAW"></VerificationCode>
            </div>
            <div class="box-card-submit">
              <el-checkbox v-model="checkStatus" class="policy-checkbox" data-testid="checkbox">
                <i18n path="withdraw.default.agreePolicy">
                  <template v-slot:link>
                    <a @click.prevent="disclaimerVisible = true" data-testid="withdrawPolicy">
                      {{ $t('withdraw.default.withdrawPolicy') }}</a
                    >
                  </template>
                </i18n>
              </el-checkbox>
              <div>
                <el-button
                  class="btn-blue submit-btn"
                  @click="submitForm()"
                  :disabled="!checkStatus"
                  data-testid="submit"
                >
                  {{ $t('withdraw.default.submitYourWithdrawal') }}
                </el-button>
              </div>
            </div>
          </div>

          <!-- notice area -->
          <InteractiveDialog :show.sync="positionDialog.show" :text="positionDialog.text">
            <template v-slot:btn>
              <div class="notice-actions" :class="{ 'one-btn': positionDialog.type !== 'Warning' }">
                <el-button
                  class="btn-blue"
                  v-if="positionDialog.showConfirm"
                  @click="equityConfirm(equityConfirmCallback)"
                  data-testid="confirm"
                >
                  {{ $t('common.button.confirm') }}
                </el-button>
                <el-button
                  class="btn-default"
                  v-if="positionDialog.showCancel"
                  @click="hidePositionDialog"
                  data-testid="cancel"
                >
                  {{ $t('common.button.cancel') }}
                </el-button>
              </div>
            </template>
          </InteractiveDialog>

          <!-- policy dialog -->
          <GeneralDialog :visible.sync="disclaimerVisible" visibleHeader hideChat :extraClass="'policy-dialog'">
            <div>
              <ul v-html="$t('withdraw.default.disclaimer', { platform: $config.info.fullName })"></ul>
            </div>
          </GeneralDialog>

          <!-- check bonus usage dialog -->
          <CheckDialog
            :isVisible.sync="tradingBonus.checkVisible"
            @onConfirm="submitWithdraw"
            :mainMessage="$t('promotion.tradingBonus.endedTip')"
          ></CheckDialog>
        </div>
      </div>
    </div>

    <!-- withdraw result -->
    <div class="result-wrapper" v-show="!formFlag">
      <div class="result-container">
        <Result v-if="successFlag" :fail="result.fail">
          <div>
            <div class="mb-2">{{ result.message }}</div>
          </div>
        </Result>
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import IncompleteRemind from './components/IncompleteRemind.vue'
// components
import NumericInput from '@/components/NumericInput'
import PendingAction from '@/components/home/PendingAction'
import Notification from '@/components/home/Notification'
import UploadIdOrAddress from '@/components/home/UploadIdAddress/UploadIdOrAddress'
import CcMethod from '@/components/withdraw/CcMethods'
import TopLeft from '@/components/withdraw/TopLeft'
import TopRight from '@/components/withdraw/TopRight'
import OtherMethod from '@/components/withdraw/OtherMethod'
import GeneralDialog from '@/components/dialog/general/Dialog.vue'
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue'
import VerificationCode from '@/components/withdraw/VerificationCode'
import Result from '@/components/Result'
import CheckDialog from '@/components/promotion/common/CheckDialog.vue'
// mixins
import mixin from '@/mixins'
import idPoaMixin from '@/mixins/idPoa'
import checkPositionMixin from '@/mixins/page/tools/checkPosition'
import uscMixin from '@/mixins/usc'
/**
 *  'promotionNoDeopositMixin' need to add mixins if no deposit bonus re-online.
 */
import promotionNoDeopositMixin from '@/mixins/promotion/noDepositBonus'
import promotionTradingBonusMixin from '@/mixins/promotion/tradingBonus'
// utils
import splitThousands from '@/util/splitThousands'
// api
import { apiApplyWithdrawal_cp_batch } from '@/resource'

export default {
  name: 'WithdrawFunds',
  components: {
    IncompleteRemind,
    NumericInput,
    PendingAction,
    Notification,
    UploadIdOrAddress,
    TopLeft,
    TopRight,
    OtherMethod,
    GeneralDialog,
    InteractiveDialog,
    Result,
    CcMethod,
    CheckDialog,
    VerificationCode,
  },
  mixins: [mixin, idPoaMixin, checkPositionMixin, uscMixin, promotionNoDeopositMixin, promotionTradingBonusMixin],
  data() {
    return {
      uploadIdOrAddress: {
        visible: false,
      },
      formFlag: true,
      withdrawForm: {
        formAccount: '',
        amount: 0,
        withdrawalType: '',
      },
      topForm: {
        creditCardBalances: [],
        showCC: false,
        showOtherPayment: false,
        totalCcBalance: 0,
        userCountryCode: '',
      },
      resetForm: true,
      showRight: false,
      disableOtherMeThod: false,
      chooseWithdraw: {},
      otherType: null,
      bankList: {},
      checkStatus: true,
      disclaimerVisible: false,
      showWithdrawalDialog: false,
      accountInfo: null,
      successFlag: false,
      result: {
        message: this.$t('withdraw.default.success'),
        fail: false,
      },
      AMLAlert: { units: 100, charge: 20 },
    }
  },
  computed: {
    showWithdraw() {
      return (
        !this.requireIDProof &&
        !this.requireAddressProof &&
        !this.pendingIDProofApproval &&
        !this.pendingAddressProofApproval
      )
    },
    showSubmit() {
      return !this.resetForm && (this.otherType || (this.topForm.showCC && !this.topForm.showOtherPayment))
    },
  },
  methods: {
    pendingActionResponse() {
      this.uploadIdOrAddress.visible = true
    },
    getTopForm(topForm, countryBankList, showRight) {
      this.topForm = { ...topForm }
      this.bankList = countryBankList
      this.showRight = showRight
    },
    getForm(form) {
      this.withdrawForm = { ...form }
    },
    reset(bool) {
      this.resetForm = bool
      this.checkStatus = true
      // this.disableOtherMeThod = false;
    },
    onDisableOtherMethod(bool) {
      this.disableOtherMeThod = bool
    },
    getWithdraw(val) {
      this.chooseWithdraw = val
      this.otherType = val.withdrawalType
      if (val.chooseWithdrawalType) this.$refs.topLeft.checkMinLimit(val.chooseWithdrawalType.amountLimit)
    },
    validComponent(otherMethodComponent, ccMethodComponent, verifyCodeComponent) {
      return [
        otherMethodComponent ? otherMethodComponent.submitForm() : Promise.resolve(),
        ccMethodComponent ? ccMethodComponent.submitForm() : Promise.resolve(),
        verifyCodeComponent.submitForm(),
      ]
    },
    submitForm() {
      // 父組件驗證
      const [getOtherMethodComponent, getCcMethodComponent, getVerifyCodeComponent] = [
        this.$refs.otherMethod,
        this.$refs.ccMethod,
        this.$refs.verificationCode,
      ]
      const [verifyOtherMethod, verifyCcMethod, verifyCodeMethod] = this.validComponent(
        getOtherMethodComponent,
        getCcMethodComponent,
        getVerifyCodeComponent,
      )
      Promise.all([verifyOtherMethod, verifyCcMethod, verifyCodeMethod]).then(valid => {
        if (valid) {
          this.checkPosition(
            this.withdrawForm.account,
            this.withdrawForm.amount,
            'withdraw',
            this.equityConfirmCallback,
          )
        } else return false
      })
    },
    checkNoDeoposit() {
      if (this.isSelectedPromotionAccount) {
        let ratedAmount =
          this.currentCurrency === 'USD' ? this.withdrawForm.amount : this.withdrawForm.amount * this.rate
        let userWithdrawlConstrain = this.promotionMinLimit && this.promotionMaxLimit
        if (userWithdrawlConstrain && this.promotionMinLimit <= ratedAmount <= this.promotionMaxLimit) {
          this.noDepositCheckVisible = true
        } else {
          this.submitWithdraw()
        }
      } else {
        this.submitWithdraw()
      }
    },
    postApiApplyWithdrawal_cp() {
      // get data from CreditCard & OtherMethod components
      const [getOtherMethodComponent, getCcMethodComponent, getVerifyCodeComponent] = [
        this.$refs.otherMethod,
        this.$refs.ccMethod,
        this.$refs.verificationCode,
      ]

      let apiData = []
      let getVerifyCode = getVerifyCodeComponent.getFormDataOfChild()
      if (this.topForm.showCC) {
        const topFromData = { ...getCcMethodComponent.getFormDataOfChild() }
        if (topFromData.creditCardWithdrawalRequests.length > 0) {
          apiData.push({
            ...getCcMethodComponent.getFormDataOfChild(),
            ...getVerifyCode,
          })
        }
      }
      if (this.topForm.showOtherPayment)
        apiData.push({
          ...getOtherMethodComponent.getFormDataOfChild(),
          ...getVerifyCode,
        })

      return apiApplyWithdrawal_cp_batch(apiData, this.token)
    },
    submitWithdraw() {
      // main submit function
      this.postApiApplyWithdrawal_cp()
        .then(result => {
          if (result && (result.data.code === 0 || result.data.code === 300)) {
            this.formFlag = false
            this.successFlag = true
            if (result.data.code === 300) {
              // for credit card fail
              this.result.fail = true
              this.result.message = this.$t('withdraw.default.ccFail', {
                email: this.GLOBAL_CONTACT_EMAIL,
              })
            }
          }
        })
        .catch(() => {
          this.$message({
            message: this.$t('withdraw.default.failed'),
            type: 'error',
          })
        })
    },
    equityConfirmCallback() {
      this.getActiveTradingBonusAccount().then(activeAccount => {
        if (`${activeAccount}` === `${this.withdrawForm.account}`) {
          this.tradingBonus.checkVisible = true
        } else {
          this.submitWithdraw()
        }
      })
      /**
       * no deposit bonuse campaign temporarily removed.
       */
      //this.checkNoDeoposit()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdrawFunds.scss';
@import '@/assets/css/components/common/Input';
.mb-2 {
  margin-bottom: 8px;
}

/deep/ .verification-code-container {
  justify-content: start !important;
  .verification-code-item + .verification-code-item {
    margin-left: 36px;
  }
  .verification-code-item {
    .el-button {
      height: 39px;
      padding: 8px 16px;
    }
  }
}
</style>
