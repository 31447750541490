<template>
  <el-form
    :model="withdrawForm"
    ref="withdrawForm"
    :rules="withdrawFormRules"
    class="top-left"
    :class="{ 'left-style': showRight }"
  >
    <el-form-item>
      <div class="title">
        <span>{{ $t('withdraw.default.accountDetail') }}</span>
      </div>
    </el-form-item>

    <!-- select account -->
    <div class="accSelect">
      <SelectForm
        v-model="withdrawForm.formAccount"
        name="formAccount"
        class="showPlaceholder"
        popperClass="withdraw-select"
      >
        <el-option
          v-for="item in formAccountOptions"
          :key="item.login"
          :value="JSON.stringify(item)"
          :label="getCurrencySymbol(item)"
          :data-testid="item.login"
        ></el-option>
      </SelectForm>
    </div>

    <!-- account type -->
    <el-form-item class="labelTitle">
      <div>
        <label for="">{{ $t('common.field.accType') }}</label>
      </div>
      <div class="row">{{ mtAccountType }}</div>
    </el-form-item>

    <!-- amount input -->
    <div class="amount">
      <el-form-item :label="$t('withdraw.default.withdrawalAmount')" prop="amount">
        <numeric-input
          class="plain-input"
          ref="numberInput"
          v-model="withdrawForm.amount"
          :currency="withdrawForm.currency"
          :precision="2"
        ></numeric-input>
      </el-form-item>
    </div>

    <!-- continue button -->
    <div class="form-button continue-btn">
      <el-button class="btn-blue" @click="submit" data-testid="submit">
        {{ $t('common.button.continue') }}
      </el-button>
    </div>
  </el-form>
</template>

<script>
import SelectForm from '@/components/form/Select'
import NumericInput from '@/components/NumericInput'
import splitThousands from '@/util/splitThousands'
import uscMixin from '@/mixins/usc'
import rounding from '@/util/rounding'
import { apiGetWithdrawalData_cp, apiCreditCardBalances, apiQueryRateUSDByTargetCurrency } from '@/resource'

export default {
  name: 'TopLeft',
  components: { NumericInput, SelectForm },
  mixins: [uscMixin],
  data() {
    return {
      creditCardBalances: [],
      formAccountOptions: [],
      countryBankList: [],
      userCountryCode: null,
      mtAccountType: null,
      withdrawForm: {
        formAccount: '',
        account: '',
        amount: 0,
        mtCategory: '',
        currency: null,
        balance: null,
      },
      currencyRate: null,
      minlimit: this.$config.withdrawalDefaultAmountMin,
      disabled: false,
      topForm: {
        showOtherPayment: false,
      },
      reset: true,
      showRight: false,
    }
  },
  mounted() {
    this.fetchTradingAccount()
    this.$root.$refs.topLeft = this
  },
  computed: {
    withdrawFormRules() {
      return {
        formAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change',
          },
        ],
        amount: [
          {
            required: true,
            validator: this.validateAmount,
            trigger: 'blur',
          },
        ],
      }
    },
    totalCcBalance() {
      let total = 0
      this.creditCardBalances.forEach(cc => {
        if (cc.creditCard.is_del !== 1 && !cc.isExpired && !cc.isPending) {
          total = total + cc.balance
        }
      })
      return total
    },
    nonCcWithdrawAmount() {
      return parseFloat(rounding(Math.round, this.withdrawForm.amount - this.totalCcBalance, 2))
    },
    isOnlyWithdrawalCc() {
      return this.totalCcBalance > 0 && this.nonCcWithdrawAmount < this.minlimit
    },
    showOtherPayment() {
      /**
       * 2023/02/09
       * Credit-Card-withdrawlal has no limit.
       * Non-Credit-Card-withdrawal limit to 40. (JPY, HKD need to equal to 40 USD.)
       */
      if (this.isOnlyWithdrawalCc) {
        return false
      }

      return this.withdrawForm.amount > this.totalCcBalance || this.creditCardBalances.length <= 0
    },
  },
  watch: {
    withdrawForm: {
      handler(form) {
        this.$emit('setForm', form)
        this.$emit('reset', true)
        this.reset = true
      },
      deep: true,
    },
    'withdrawForm.formAccount': {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          const jsonParse = JSON.parse(newValue)
          this.withdrawForm.account = jsonParse.login
          this.withdrawForm.balance = jsonParse.balance
          this.withdrawForm.mtCategory = jsonParse.mtCategory
          if (this.withdrawForm.currency === jsonParse.currency) {
            this.$refs.numberInput.updateCurrency(true)
          } else {
            // if currency has changed, call the converRate api
            await this.convertUSDToTargetCurrency(jsonParse.currency)
          }

          this.withdrawForm.currency = jsonParse.currency

          this.$refs['withdrawForm'].fields.find(f => f.prop == 'amount').resetField()

          this.mtAccountType = jsonParse.mtAccountTypeDisplay

          this.disabled = false
        }
      },
    },
    'withdrawForm.amount'(value) {
      this.disabled = false
    },
    formAccountOptions(options) {
      if (options.length > 0) {
        this.withdrawForm.formAccount = JSON.stringify(options[0])
      }
    },
  },
  methods: {
    convertUSDToTargetCurrency(currency, equalValue = this.$config.withdrawalDefaultAmountMin) {
      const apiCurrencyList = this.$config.usdRateFromApiCurrencyList
      if (!apiCurrencyList.includes(currency)) {
        this.minlimit = equalValue
        return
      }

      return apiQueryRateUSDByTargetCurrency(currency)
        .then(res => {
          if (res.data.code === 0 && res.data.data) {
            this.minlimit = equalValue * Number(res.data.data)
          } else {
            this.minlimit = equalValue
          }
        })
        .catch(error => {
          this.minlimit = equalValue
        })
    },
    fetchTradingAccount() {
      apiGetWithdrawalData_cp()
        .then(resp => {
          if (resp.data.code === 0) {
            this.userCountryCode = parseInt(resp.data.data.countryCode)
            this.formAccountOptions = resp.data.data.logins //账号信息
            this.countryBankList = resp.data.data.withdrawBankList
          }
        })
        .catch(() => {
          this.$message({
            message: this.$t('withdraw.default.fetchTradeAccFailed'),
            type: 'error',
          })
        })
    },
    submit() {
      this.$refs['withdrawForm'].validate(valid => {
        if (valid) {
          this.getApiCreditCardBalances()
        } else return false
      })
    },
    async checkMinLimit(amount) {
      // While user chooses second payment (none-credit-card methods) from TopRight component,
      // assign the specific payment method's minAmountLimit to minlimit.
      // Limitations of each withdraw payments refer to data get from apiGetNonCreditCardWithdrawTypeCP.
      await this.convertUSDToTargetCurrency(this.withdrawForm.currency, amount)
      this.emitTopForm(this.creditCardBalances)
      this.$refs['withdrawForm'].validate()
    },
    getApiCreditCardBalances() {
      apiCreditCardBalances({ currency: this.withdrawForm.currency })
        .then(resp => {
          this.creditCardBalances = resp.data
          this.showRight = true
          this.emitTopForm(this.creditCardBalances)
          this.$emit('reset', false)
        })
        .catch(err => {
          console.log(err)
        })
    },
    emitTopForm(creditCardBalances) {
      let showCC = creditCardBalances.length > 0 ? true : false
      this.topForm = {
        creditCardBalances: creditCardBalances,
        showCC: showCC,
        showOtherPayment: this.showOtherPayment,
        totalCcBalance: this.totalCcBalance,
        userCountryCode: this.userCountryCode,
        onlyWithdrawalCc: this.isOnlyWithdrawalCc,
      }
      this.$emit('setTopForm', this.topForm, this.countryBankList, this.showRight)
      this.disabled = showCC || this.showOtherPayment
    },
    getCurrencySymbol(item) {
      return (
        item.login +
        ' - ' +
        item.accountDisplayType +
        ' - ' +
        this.$options.filters.currencyToSymbol(item.currency) +
        splitThousands(item.balance, 2) +
        ' ' +
        item.currency
      )
    },
    resetWithdrawalData() {
      this.$emit('reset', true)
      this.getApiCreditCardBalances()
    },
    validateAmount(rule, value, callback) {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (parseFloat(value) <= 0) {
        // CANADA ONLY
        if (this.withdrawForm.currency === 'CAD') {
          callback(
            new Error(
              this.$t('common.formValidation.amtCAD', {
                minLimit: this.minlimit,
                currency: 'CAD',
              }),
            ),
          )
        } else {
          callback(new Error(this.$t('common.formValidation.amt0')))
        }
      } else if (value < this.minlimit) {
        // CANADA ONLY
        if (this.withdrawForm.currency === 'CAD') {
          callback(
            new Error(
              this.$t('common.formValidation.amtCAD', {
                minLimit: this.minlimit,
                currency: 'CAD',
              }),
            ),
          )
        } else {
          callback(
            new Error(
              this.$t('common.formValidation.amtLarger', {
                minLimit: Math.ceil(this.minlimit),
                currency: this.withdrawForm.currency,
              }),
            ),
          )
        }
      } else if (value > this.withdrawForm.balance) {
        callback(new Error(this.$t('withdraw.default.YourPaymentGreaterThanAvailableBalance')))
      } else callback()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/topLeft.scss';
</style>
