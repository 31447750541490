<template>
  <el-form-item label class="upload">
    <el-upload
      :action="action ? action : '/api/file/upload'"
      :limit="limit"
      :accept="accept"
      :before-upload="examFile"
      :before-remove="deleteFile"
      :on-success="saveUploadPath"
      :on-error="uploadError"
      :data-testid="dataTestId"
    >
      <slot></slot>
      <div class="upload-demo" v-if="!selfText">
        <img src="@/assets/images/withdraw/add-file.png" alt="" />
        <div class="button-upload" data-testid="button_upload">{{ $t('common.button.selectFile') }}</div>
      </div>
      <div slot="tip" class="el-upload__tip" v-if="!selfTip">
        <ul>
          <li>{{ $t('fileUploader.supportedFormat') }}</li>
          <li>{{ $t('fileUploader.maxSize', { maxSize: 5 }) }}</li>
          <li>{{ $t('fileUploader.maxFileNum', { maxFileNum: limit }) }}</li>
        </ul>
      </div>
    </el-upload>
  </el-form-item>
</template>

<script>
import { apiDelete_file } from '@/resource';
export default {
  name: 'vUpload',
  props: {
    action: String,
    limit: Number,
    selfTip: Boolean,
    selfText: Boolean,
    dataTestId: {
      type: String,
      default: 'upload'
    }
  },
  data() {
    return {
      accept: 'image/png, image/jpeg, image/jpg, image/bmp, .pdf, .doc, .docx',
      fileInfo: {
        fileList: []
      },
      allowedFile: ['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'doc', 'docx']
    };
  },
  methods: {
    updateFileInfo() {
      this.$emit('updateFileInfo', this.fileInfo);
    },
    updateDeletedFile(file) {
      this.$emit('updateDeletedFile', file);
    },
    examFile(file) {
      if (file.size / 1024 / 1024 > 5) {
        console.log('file size ' + file.size + ' is over size');
        return false;
      }

      if (this.isFileSupported(file)) {
        return true;
      } else {
        console.log('unsupported file type: ' + file.name);
        return false;
      }
    },
    isFileSupported(file) {
      return this.allowedFile.some(s => {
        return file.name.toLowerCase().endsWith(s);
      });
    },
    deleteFile(file) {
      if (this.fileInfo.fileList.length == 0) {
        return true;
      } else if (file.response) {
        return apiDelete_file({ fileId: file.response.data })
          .then(result => {
            if (result.data.code == 0) {
              this.fileInfo.fileList = this.fileInfo.fileList.filter(item => item != file.response.data);
              this.updateFileInfo();
              this.updateDeletedFile(file.response.data);
              return Promise.resolve(true);
            } else {
              console.log(result.data.errmsg);
              return Promise.reject();
            }
          })
          .catch(err => {
            console.log(err);
            return Promise.reject();
          });
      }
    },
    saveUploadPath(response) {
      if (response.code == 0) {
        this.fileInfo.fileList.push(response.data);
        this.updateFileInfo();
      } else if (response.code == 'session_timeout') {
        this.$router.push(response.loginUrl);
      }
    },
    uploadError(response) {
      this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/vUpload.scss';
.upload.el-form-item /deep/ {
  margin-bottom: 0 !important;
}
.upload-demo {
  background-color: #d8d8d8;
  height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 36px;
    height: 36px;
  }

  .button-upload {
    margin-left: 0;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
}

/deep/ .el-upload {
  border: none;
}

.el-upload__tip {
  color: $future-blue;
  margin-top: 20px;

  ul {
    margin-left: 16px;
  }
  li {
    list-style: disc;
  }
}
</style>
